<template>
  <!--客户API订单记录-->
  <div style="padding: 20px;">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="4" :md="12">
                  <el-form-item label="关键词:">
                    <el-input
                      clearable
                      v-model="where.keyword"
                      placeholder="请输入关键字"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="4" :md="12">
                  <el-form-item label="记录类型:">
                    <el-select
                      clearable
                      v-model="where.type"
                      placeholder="请选择"
                      class="ele-fluid">
                      <el-option label="创建" value="create"/>
                      <el-option label="更新" value="update"/>
                      <el-option label="催单" value="reminder"/>
                      <el-option label="投诉" value="complaint"/>
                      <el-option label="取消" value="cancel"/>
                      <el-option label="其他" value="other"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :lg="7" :md="12">
                  <el-form-item label="时间:">
                    <el-date-picker
                      value-format="yyyy-MM-dd"
                      v-model="where.time"
                      type="daterange"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList">查询
                    </el-button>
                    <el-button @click="reset">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :total="total"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :customsFromWhere="where"
              :loading="loading"
            >
              <template v-slot:operationSlot="{ scope }">
                <el-link
                  :underline="false"
                  type="warning"
                  @click="Todetalis(scope.row)"
                  style="margin-right: 10px"
                >详情</el-link
                >
              </template>
            </my-table>
          </div>
        </div>
      </el-card>
    </div>

    <el-dialog title="详情" :visible.sync="dialogVisible" width="80%" :close-on-click-modal="false" top="5vh" >
      <!-- <span>这是一段信息</span> -->
      <div v-loading="loading1">
        <div style="margin-bottom: 20px;">
          <div>
            appid：{{current.appid}}
          </div>
          <div>
            客户编码：{{current.client_code}}
          </div>
          <div>
            客户名称：{{current.client_name}}
          </div>
          <div>
            创建时间：{{current.create_time}}
          </div>
          <div>
            服务商名称：{{current.firm_name}}
          </div>
          <div>
            保司名称：{{current.insurer_name}}
          </div>
          <div>
           订单编号： {{current.odd}}
          </div>
          <div>
           类型： {{current.type}}
          </div>
        </div>

        <el-row :gutter="15">
          <el-col :span="12">
            <div>原数据</div>
            <pre id="out_pre"></pre>
          </el-col>
          <el-col :span="12">
            <div>新数据</div>
            <pre id="out_pre1"></pre>
          </el-col>
        </el-row>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="quxiao">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 权限
import {ShowTable} from '@/utils'

// 引入的接口
import {
  api_order_log, api_order_log_info,
} from '@/api/system';

export default {
  components:{

  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 100 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "订单编号",
          prop: "odd",
          isShow: true
        },
        {
          label: "企业名称",
          prop: "firm_name",
          isShow: true
        },
        {
          label: "保司名称",
          prop: "insurer_name",
          isShow: true
        },
        {
          label: "客户名称",
          prop: "client_name",
          isShow: true
        },
        {
          label: "记录类型",
          prop: "type_label",
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          fixed:'right',
          slot: "operationSlot",
          isShow: true
        },
      ],


      //新增
      dialogVisible: false,
      current:{
        source_data:'',
        compile_data:'',
        client_name:'',
        client_code:'',
        insurer_name:'',
        firm_name:"",
        create_time:'',
        type:'',
        odd:'',
      },


      //弹窗是否显示
      isUpdate:false,

      loading1:false,

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.getList();
  },

  methods:{
    /* 重置搜索 */
    reset() {
      this.where = {
        page: 1,
        limit: 100
      };
      this.getList();
    },

    // 获取列表
    getList(){
      this.loading = true;
      api_order_log(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.where.page = 1;
      this.where.limit = 100;
      this.getList();
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },


    //点击球销
    quxiao(){
      this.dialogVisible = false;
    },

    //点击详情
    Todetalis(row){
      this.loading1 = true;
      api_order_log_info(row.id).then(res => {
        if(res.code == 200){
          console.log(res);
          this.current = res.data;
          this.dialogVisible = true;


          this.$nextTick(() => {
            var result = JSON.stringify(JSON.parse(this.current.source_data), null, 2);//将字符串转换成json对象
            document.getElementById('out_pre').innerText= result ;

            var result1 = JSON.stringify(JSON.parse(this.current.compile_data), null, 2);//将字符串转换成json对象
            document.getElementById('out_pre1').innerText= result1 ;
          })


          console.log(this.dialogVisible);
          this.loading1 = false;
        }else {
          this.$message.error(res.msg);
        }
      })
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
